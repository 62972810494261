import Service from "../Service";

const resource = "FarmLot/";

export default {
    save(flt, requestID) {
        console.log('llamando a serviciossss', flt, requestID)
        return Service.post(resource + "save", flt, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    searchlottransfer(parameters, requestID) {
        return Service.post(resource + "searchlottransfer", parameters, {
            params: { requestID: requestID },
        });
    },
    //pdf
};