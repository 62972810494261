<template>
  <div>
    <s-crud 
      title="Certificaciones" 
      :filter="filter" height="150"
      @rowSelected="rowSelected($event)"
      :config="configCertificate" 
      @save="saveCertificate($event)"
      :add="this.$fun.getSecurity().IsLevelAdmin"
      :edit="this.$fun.getSecurity().IsLevelAdmin"
      :remove="this.$fun.getSecurity().IsLevelAdmin"
      ref="scrudcertification"
    >
      <template slot-scope="props">
        <v-col>
          <v-row justify="center">
            <!-- <v-col cols="12" class="s-col-form"  sm="4" md="4" >
              <s-select-definition :def="1159" label="Tipo Certificación" add v-model="props.item.TypeCertification"></s-select-definition>
            </v-col> -->
            
            <v-col cols="12" class="s-col-form"  sm="4" md="4" >
              <s-select-type-certification full autofocus label="Tipo Certificación" @input="input($event)" @change="change($event)" v-model="props.item.TypeCertification"></s-select-type-certification>
            </v-col>

            <v-col cols="12" class="s-col-form"  sm="4" md="4">
              <s-select-type-certification-detail full :TcID="TcID" label="Adenda" @cleanFields="cleanFields" :text="props.item.TypeCertificationDetailText" v-model="props.item.TypeCertificationDetail"></s-select-type-certification-detail>
            </v-col>

            <v-col cols="12" class="s-col-form" sm="4" md="4"> 
              <s-date v-model="props.item.CrtDateBegin" label="Fecha Inicio"/>
            </v-col>

            
          </v-row>

          <v-row justify="center">
            <v-col cols="12" class="s-col-form" sm="4" md="4">
              <s-date v-model="props.item.CrtDateEnd" label="Fecha Fin" />
            </v-col>

            <v-col cols="12" class="s-col-form" sm="4" md="4">
              <s-text v-model="props.item.CrtZonaCertified" decimal label="Área Certificada"></s-text>
            </v-col>
            <!-- <v-col cols="12" class="s-col-form" sm="4" md="4">
              <s-text @keyup="saveCertificate($event)" decimal v-model="props.item.CrtVolumeCertified" label="Volumen"></s-text>
            </v-col> -->
            <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
              <s-text v-model="props.item.CrtNumberCertificate" :maxlength=64 label="GGN Titular Certificado" />
            </v-col> 
            
          </v-row>

          <v-row justify="center">
            <v-col cols="12" class="s-col-form" sm="4" md="5" lg="4">
              <s-text v-model="props.item.CrtCode" label="GGN Código"></s-text>
            </v-col> 
            <v-col cols="12" class="s-col-form" sm="6" md="4" lg="4">
              <s-select-definition v-model="props.item.CrtStatus" :def="1152" label="Estado"></s-select-definition>
            </v-col>
            <v-col cols="12" class="s-col-form" sm="6" md="4" lg="4">
              <s-select-definition v-model="props.item.StatusCertification" :def="1192" label="Estado Certificación"></s-select-definition>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" class="s-col-form" md="8" lg="4">
              <s-text v-model="props.item.CrtCertificateHolder" :label="TcSelected"></s-text>
            </v-col>
            <v-col cols="12" class="s-col-form" md="8" lg="4">
              <s-text v-model="props.item.CrtObservation" label="Observacion"></s-text>
            </v-col>
            <v-col cols="12" class="s-col-form" md="8" lg="4">
              <s-load label="Seleccione Archivo" v-model="files"></s-load>
            </v-col>
          </v-row>

        </v-col>
      </template>

      <template v-slot:CrtStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.CrtStatus == 1 ? 'success' : 'error'">
          {{ row.CrtStatus == 1 ? "Activo" : "Vencido" }}
        </v-chip>
      </template>

      <template v-slot:CrtNameCertificationFile="{ row }">
        <v-btn icon color="primary" v-if="row.CrtNameCertificationFile != ''" x-small @click="descargarFile($event)">
          <v-icon>fas fa-file-download</v-icon>
        </v-btn>
      </template>

      <template v-slot:StatusCertification="{ row }">
        <v-btn icon color="primary" v-if="row.StatusCertification == 1 && row.CrtProcessCertification == 0" x-small @click="certify(row)">
          <v-icon>fas fa-stamp</v-icon>
        </v-btn>
        <v-icon v-else></v-icon>
      </template>

    </s-crud>

    <enter-certification-date :dialog="dialog" :rowSel="rowSel" @hideModal="hideModal" @freshCertification="freshCertification"></enter-certification-date>

  </div>
</template>
<script>
import _sCertificationsService from "@/services/Technicalassistance/CertificationsService";
import SSelectTypeCertification from '../../../components/Utils/Certification/sSelectTypeCertification.vue';
import SSelectTypeCertificationDetail from '../../../components/Utils/Certification/sSelectTypeCertificationDetail.vue';

import _sHelper from "@/services/HelperService";
import EnterCertificationDate from '../../../components/TecnicalAssistence/CertificationSchedule/EnterCertificationDate.vue';

export default {
  components: {SSelectTypeCertification, SSelectTypeCertificationDetail, EnterCertificationDate},
  props: {
    parentID: { type: Number, default: 0 },
    
    farmLotID: { type: Number, default: 0},
    isAdmin: { type: Boolean, default: false },
  },

  data: () => ({
    allItems: [],
    objCertification: {
      certificationObj:null,
    },
    objCertificationDetail: {
      certificacionDetailObj: null
    },
    TcID: 0,
    rowSel: {},
    CsID: 0,
    dialog: false,
    files: [],
    filter: {},
    itemSelected: {},
    configCertificate: {
      model: {
        CrtID: "ID",
        FltID: "int",
        CrtCode: "string",
        SecStatus: "boleam",
        TypeCertification: "int",
        TypeCertificationText: "string",
        TypeCertificationDetail: "int",
        CrtDateBegin: "date",
        CrtDateEnd: "date",
        CrtStatus: "int",
        StatusCertification: "string",
        CrtNameCertificationFile: "string",
        TcID: "int"
      },
      service: _sCertificationsService,
      headers: [
        { text: "ID", value: "CrtID" },
        { text: "Tipo de Certificación", value: "TypeCertificationText" },
        { text: "Adenda", value: "TypeCertificationDetailText" },
        { text: "Observación", value: "CrtObservation" },
        { text: "Código", value: "CrtCode" }, 
        { text: "GGN Titular", value: "CrtNumberCertificate" },
        { text: "F.Inicio", value: "CrtDateBegin" },
        { text: "F.Fin", value: "CrtDateEnd" },
        /* { text: "Volumen Cert.", value: "CrtVolumeCertified" }, */
        { text: "Estado", value: "CrtStatus" },
        { text: "Status", value: "StatusCertificationText" },
        { text: "Certificar", value: "StatusCertification" },
        { text: "Descargar Certificado", value: "CrtNameCertificationFile" },
      ],
    },
    TcSelected: ""
  }),
  methods: {
    rowSelected(rows) {
      if (rows.length > 0) {
        this.itemSelected = rows;
      }
    },

    input(value)
    {
      if(value){
        this.TcID = value;
      }
      if(this.allItems.length > 0){
        if(this.TcID != 0){
          this.TcSelected = "Cod. Certificación "+this.allItems[this.TcID - 1].TcDescription;
        }else{
          this.TcSelected = "Cod. Certificación "+this.allItems[0].TcDescription;
        }
      }
      
    },

    change(items)
    {
      this.allItems = items;
    },

    cleanFields()
    {
      this.objCertificationDetail.TcdID = null;
    },

    Initialize() {
      this.filter = { FltID: this.farmLotID };
    },

    saveCertificate(item) {
      if(item.CrtZonaCertified === ""){
        this.$fun.alert("Ingrese Área certificada", "warning");
        return;
      }
      if(item.CrtDateBegin == ""){
        this.$fun.alert("Ingrese Fecha Inicio certificada", "warning");
        return;
      }
      if(item.CrtDateEnd == ""){
        this.$fun.alert("Ingrese Fecha Fin", "warning");
        return;
      }
      if(isNaN(item.CrtZonaCertified)){
        this.$fun.alert("Área certifica no valida", "warning");
        return;
      }
      item.FltID = this.farmLotID;
      if (isNaN(item.TypeCertification) || item.TypeCertification == 0) {
        this.$fun.alert("Seleccione Certificacion", "warning");
        return;
      }
      item.CrtNameCertificationFile = this.files.name != null ? this.files.name : '';
      item.CrtProcessCertification = 0;
      item.save()
      .then(resp => {
        this.objCertification.TcID = 0;
        this.objCertificationDetail.TcdID = 0;
        this.subirCerfificacion();
      });
      
    },

    subirCerfificacion()
    {
      if(this.files != null){
        var fileName = this.files.name;
        var formData = new FormData();
        formData.append("file", this.files);
        _sHelper.uploadfile(1, this.$fun.getUserID(), formData, 5).then((response) => {
          if(response.status == 200){
            this.files = [];
            return;
          }
        });
      }
      else{
        this.$fun.alert("Sin datos que subir");
      }
    },

    descargarFile()
    {
      let nameFile = this.itemSelected[0].CrtNameCertificationFile;
      this.$fun.alert("¿Seguro de descargar?", "question").then((r) => {
        if(r.value){
          _sHelper.getfile(1, this.$fun.getUserID(), nameFile, 5).then((r) => {
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", nameFile);
            document.body.appendChild(link);
            link.click();
          });
        }
      });
    },

    hideModal()
    {
        this.dialog = false;
    },
    certify(row)
      {
        this.$fun.alert("¿Seguro de certificar?", "question").then((r) => {
            if(r.value){
                this.rowSel = row;
                this.dialog = true;

                /* _CertificacionScheduleService.masscertification(CsID, this.$fun.getUserID())
                .then((r) => {
                    this.$fun.alert("Certificados Correctamente", "success");
                    this.$refs.crudschechulecertification.refresh();
                 //   this.$ref.fresh("crudschechulecertification"); NO VOLVER HACER
                }) */
            }
        });

    },
    freshCertification()
    {
      this.$refs.scrudcertification.refresh();
    },
    
  },
  watch: {
    TcID(){
    },
    parentID() {
      this.Initialize();
    },
    farmLotID() {
      this.Initialize();
    }
  },
  created() {
    this.filter = { FltID: this.parentID };
    
  },
};
</script>
