import Service from "../Service";

const resource = "certificationschedule/";

export default {
    save(tcs, requestID) {
        return Service.post(resource + "save", tcs, {
            params: { requestID }
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID }
        });
    },

    masscertification(CsID, requestID) {
        return Service.post(resource + "masscertification", {}, {
            params: { CsID, requestID }
        });
    }
}