<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      persistent
    >
      <v-card>
        <s-toolbar  
            color="primary" 
            save
            @save="save"
            dark
            close
            @close="close"
            label="Fechas"
        >
        </s-toolbar>

        <v-card-text>
         <v-container>
            <v-row>
              <v-col cols="12" class="s-col-form" sm="6" md="6"> 
                <s-date v-model="dateBegin" label="Fecha Inicio"/>
              </v-col>
              <v-col cols="12" class="s-col-form" sm="6" md="6">
                <s-date v-model="dateEnd" label="Fecha Fin" />
              </v-col>
          </v-row>
         </v-container>
        </v-card-text>

        <v-divider></v-divider>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sCertificationRequirementService from "@/services/Technicalassistance/CertificationRequirementService";
import _sCertificationProducerRequirementService from "@/services/Technicalassistance/CertificationProducerRequirementService";
import _CertificacionScheduleService from "@/services/Technicalassistance/CertificationScheduleService";
import _sCertificationsService from "@/services/Technicalassistance/CertificationsService";

  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      CsID: {
        type: Number,
        default: 0
      },
      CpID: {
        type: Number,
        default: 0
      },
      rowSel: {
        type: Object,
        default: {}
      }
    },
    data () {
      return {
        dateBegin: null,
        dateEnd: null,
        rowSel: {},
        singleSelect: false,
        selected: [],
        headers: [
          { text: 'ID', align: 'start', sortable: false, value: 'CrqID' },
          { text: 'Requisito', value: 'Requirement' },
          { text: 'Observación', value: 'CprObservation' },
        ],

        /* dialog: true, */
        filter: {},
        dataReceived: [],
        requirementProducer: [],
        arrayRequiremetFulfilled: [],
      }
    },
    methods: { 
      close()
      {
        this.hideModal();
      },
      itemSelected(item){
        if(item.value)
        {
          item.CpID = this.CpID;
          item.CprMeets = 1;
          item.SecStatus = 1;
          item.UsrCreateID = this.$fun.getUserID();
          item.CrqID = item.item.CrqID;
          /* this.selected.forEach((element) => {
            element.CpID = this.CpID;
            element.CprObservation = '';
            element.CprMeets = 1;
            element.SecStatus = 1;
            element.UsrCreateID = this.$fun.getUserID();
            if(!element.CprID){
              element.CprID = 0;
            }
          }); */
        }else{
          console.log('aquii');
          console.log(this.arrayRequiremetFulfilled);
          item.CpID = this.CpID;
          item.CprMeets = 0;
          item.SecStatus = 0;
          item.UsrCreateID = this.$fun.getUserID();
          item.CrqID = item.item.CrqID;
          /* this.selected.forEach((element) => {
            element.CpID = this.CpID;
            element.CprObservation = '';
            element.CprMeets = 0;
            element.SecStatus = 0;
            element.UsrCreateID = this.$fun.getUserID();
            if(!element.CprID){
              element.CprID = 0;
            }
          }); */
        }
        
        
        _sCertificationProducerRequirementService
                .save(item, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.$emit("recharge");
                })
      },
      Initialize() {
        this.filter = {CsID: this.CsID};
      },
      hideModal() {
        this.$emit('hideModal');
      },
      rowSelected(item)
      {
        console.log(this.selected);
        if (item.length > 0) {
          this.fillRequirements(item);
          /* this.arrayRequiremetFulfilled[item[0].CprID] = item[0];
          console.log('this.arrayRequiremetFulfilled', this.arrayRequiremetFulfilled) */
          /* if(item.CprMeets == 0){
            item.CprMeets = 1
          }else{
            item.CprMeets = 0;
          } */
        }
        /* console.log('itemrow', item[0])
        console.log('array', this.arrayRequiremetFulfilled) */
      },

      fillRequirements(item)
      {
        this.arrayRequiremetFulfilled = [];
        item.forEach((element) => {
          this.arrayRequiremetFulfilled.push(element);
        });
        console.log('tag', this.arrayRequiremetFulfilled)
      },

      loadDataInitial()
      {
        _sCertificationRequirementService
        .list(this.CsID, this.$fun.getUserID())
        .then((resp) => {
          console.log('res', resp.data)
          this.dataReceived = resp.data;
        })
      },

      save(){
        
        if (this.CsID != 0) {
           _CertificacionScheduleService.masscertification(this.CsID, this.dateBegin, this.dateEnd, this.$fun.getUserID())
          .then((resp) => {
            if(resp.status == 200){
              this.$fun.alert("Certificados Correctamente", "success");
              this.dialog = false;
            }
            
          });
        } else {
          this.rowSel.CrtProcessCertification = 1;
          _sCertificationsService.save(this.rowSel, this.$fun.getUserID())
          .then((resp) => {
            if(resp.status == 200){
              this.rowSel.CrtID = 0;
              this.rowSel.CrtDateBegin = this.dateBegin;
              this.rowSel.CrtDateEnd = this.dateEnd;
              this.rowSel.StatusCertification = 2;
              this.rowSel.CrtProcessCertification = 0;
              _sCertificationsService.save(this.rowSel, this.$fun.getUserID())
              .then((resp) => {
                if(resp.status == 200){
                  this.$fun.alert("Agregado Correctamente", "success");
                  this.dialog = false;
                  this.$emit('freshCertification');
                }
              });
            }
          });
          this.$emit('freshCertification');


          /* this.rowSel.CrtID = 0;
          this.rowSel.CrtDateBegin = this.dateBegin;
          this.rowSel.CrtDateEnd = this.dateEnd;
          this.rowSel.StatusCertification = 2;
          this.rowSel.CrtProcessCertification = 0;
          console.log('towwwww', this.rowSel);
          _sCertificationsService.save(this.rowSel, this.$fun.getUserID())
          .then((resp) => {
            if(resp.status == 200){
              this.$fun.alert("Agregado Correctamente", "success");
              this.dialog = false;
              this.$emit('freshCertification');
            }
          }); */
        }
      },
    },
    watch: {
      CsID() {
        this.Initialize();
        this.loadDataInitial();
      },
      CpID(){
        _sCertificationProducerRequirementService
        .list(this.CpID, this.$fun.getUserID())
        .then((resp2) => {
          this.requirementProducer = resp2.data;
          this.selected = this.requirementProducer;
        });
        
      },

      /* selected(){
        console.log(this.rowSel);
      } */
    },
    created() {
      this.filter = {CsID: this.CsID};
    },
  }
</script>
